.responsive-wrapper {
  height: 220px;
  /* margin-bottom: 20px; */
}
.toogle-btn {
  display: none;
}

.table-wrapper {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
}

.table-wrapper::-webkit-scrollbar {
  width: 10px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border-radius: 10px;
}

.table-wrapper::-webkit-scrollbar-track {
  background-color: #9e75754d;
  /* border-radius: 10px; */
}

.table {
  width: 100%;
}
.table-title {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #00004d;
  border-radius: 5px 5px 0 0;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}
.table-body tr {
  display: flex;
  justify-content: space-around;
}

.table-body tr td {
  background-color: transparent;
  border: none;
  font-weight: bold;
  border-bottom: 1px solid #00004d;
}

.table-body tr :first-child {
  width: 60%;
  padding-left: 10px;
}
.table-body tr :last-child {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

@media (max-width: 1030px) {
  .toogle-btn {
    display: block;
  }
  .responsive-wrapper {
    width: 70vw;
    position: absolute;
    top: 80px;
    right: 5px;
    background: linear-gradient(-30deg, #33ccff, #81d5ff, #ffccff, #81d5ff);

    height: 70vh;
    border-radius: 5px;
    z-index: 5;
    box-shadow: -10px 20px 5px rgba(0, 0, 0, 0.589);
    transition: transform 0.5s linear;
    border-right: 2px solid black;
  }

  .responsive-wrapper.open {
    transform: translateX(0);
  }

  .responsive-wrapper.close {
    transform: translateX(100vw);
  }
  .table-wrapper {
    height: 95%;
  }

  .table-wrapper::-webkit-scrollbar {
    width: 5px;
  }

  .table-wrapper::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
    border-radius: 10px;
  }

  .table-wrapper::-webkit-scrollbar-track {
    background-color: #f1f1f100;
    border-radius: 10px;
  }
}
