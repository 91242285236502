@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap");
@font-face {
  font-family: digital;
  src: url("./assets/fonts/digital.ttf");
}
@font-face {
  font-family: "GabrielSans Medium";
  src: url("./assets/fonts/GabrielSans-Trial-Medium.woff") format("woff");
}
@font-face {
  font-family: "GabrielSans Bold";
  src: url("./assets/fonts/GabrielSans-Trial-Bold.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNowDisplay Medium";
  src: url("./assets/fonts/HelveticaNowDisplay-Medium.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNowDisplay Bold";
  src: url("./assets/fonts/HelveticaNowDisplay-Bold.woff") format("woff");
}
* {
  padding: 0;
  margin: 0;
  font-family: "HelveticaNowDisplay Medium";
  font-size: 14px;
  text-decoration: none;
  list-style: none;
  user-select: none;
}
.rs-symbol {
  font-family: "HelveticaNowDisplay Medium";
  font-size: 35px;
  margin-right: 10px;
  color: #dd1947;
}

body {
  background: linear-gradient(-30deg, #33ccff, #81d5ff, #ffccff, #81d5ff);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 100vh;
  /* border: 1px solid black; */
}

.container {
  width: 95vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  /* border: 1px solid black; */
}

.container .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .navbar .logo {
  display: flex;
  align-items: center;
}

.container .navbar .logo img {
  animation: rotate 8s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
}

.container .navbar .logo .logoname {
  font-size: 18px;
  text-transform: uppercase;
  margin-left: 0.5em;
  color: #000;
  text-shadow: 1px 1px 1px rgba(16, 16, 16, 0.1),
    1px 2px 1px rgba(16, 16, 16, 0.2), 1px 3px 1px rgba(16, 16, 16, 0.1),
    1px 4px 1px rgba(16, 16, 16, 0.2), 1px 5px 1px rgba(16, 16, 16, 0.1),
    1px 6px 1px rgba(16, 16, 16, 0.2);
}

.searchbox {
  position: relative;
  display: flex;
  gap: 20px;
}
.history-button {
  cursor: pointer;
}
.history-button:hover {
  transform: scale(0.9);
}

.searchbox input {
  padding: 8px 120px 8px 20px;
  border: none;
  outline: none;
  border-radius: 30px;
  transition: 0.3s;
}

.searchbox input:focus {
  padding: 8px 250px 8px 20px;
}

.searchbox .fa {
  position: absolute;
  top: 10px;
  right: 10px;
}

.container .navbar .navitem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navitem .lang select {
  padding: 6px 30px;
  border: none;
  outline: none;
  border-radius: 6px;
  position: relative;
  right: 4em;
}

.navitem .icons {
  position: relative;
  right: 1em;
}

.navitem .icons ul li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  margin: 0 12px;
}

.navitem .icons ul li .fa {
  padding: 5px;
  color: #fff;
  background: #000;
  border-radius: 50%;
  transition: 0.3s;
}

.navitem .icons ul li .fa:hover {
  transform: scale(1.2) rotate(360deg);
  color: #33cc33;
  background: transparent;
}

.navitem .account {
  position: relative;
  display: flex;
  align-items: center;
}

.navitem .account img {
  border-radius: 50%;
}

.navitem .account .name {
  text-transform: capitalize;
  margin: 0 8px;
  font-weight: 700;
}

.navitem .account .username {
  position: absolute;
  top: 18px;
  left: 32px;
  font-size: 12px;
  color: #000;
}

.container-body {
  display: grid;
  grid-template-columns: 27% auto;

  /* It was changed from 18 to 20% to adjust the table */
}

/* -----------------------sidebar------------------- */
.container-body .sidebar {
  max-height: 80vh;
  height: 75vh;
  margin: 0px 0 20px 20px;
  background: linear-gradient(
    to top right,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 14px;
  padding: 10px;
}

.container-body .sidebar ul li {
  padding: 5px 10px;
  margin: 10px 0;
}

.container-body .sidebar ul li:hover {
  background: #00004d;
  color: #fff;
  border-radius: 8px;
}

.container-body .sidebar ul li a {
  color: #000;
  text-transform: capitalize;
  font-weight: bolder;
}

.container-body .sidebar ul li:hover a {
  color: #fff;
}

.container-body .sidebar ul .dashboard {
  background: #00004d;
  color: #fff;
  border-radius: 8px;
}

.container-body .sidebar ul .dashboard a {
  color: #fff;
}

.container-body .sidebar ul li .fa {
  font-size: 18px;
  padding: 0 5px;
}

@media (max-width: 550px) {
  .nav-logo img {
    margin: 0;
    width: 100px !important;
    text-shadow: 10px 10px 10px black;
  }

  body {
    overflow-x: hidden;
  }

  .sidebar {
    margin: 0;
  }
  .container-body .sidebar {
    width: 70px;
    padding: 5px;
    margin-left: 0;
    margin-right: 0;
  }
  .container-body .sidebar ul li {
    margin: 15px 0;
    padding: 5px 0;
  }
  .container-body .sidebar ul li a {
    font-size: 10px;
    margin: 0;
    padding: 0;
  }

  .container-body .sidebar ul .dashboard {
    background: #00004d;
    color: #fff;
    border-radius: 8px;
    margin: 15px 0;
    padding: 5px 0;
  }

  .searchbox input {
    padding: 8px 0 8px 8px;
  }

  .searchbox input:focus {
    padding: 8px 0 8px 8px;
  }

  .cards::-webkit-scrollbar {
    width: 10px !important;
  }
  .container-body .sidebar ul li:hover {
    background: none;
    color: #000000;
  }
  .container-body .sidebar ul li:hover a {
    color: #000000;
  }

  .container-body .sidebar ul .dashboard {
    background: none;
    border-radius: 8px;
    position: relative;
  }
  .container-body .sidebar ul .dashboard::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #00004d;
  }

  .container-body .sidebar ul .dashboard a {
    color: #000000;
  }
}

@media (min-width: 435px) and (max-width: 550px) {
  body {
    overflow-x: hidden;
  }

  .sidebar {
    margin: 0;
  }
  .container-body .sidebar {
    width: 70px;
    padding: 5px;
    margin-left: 0;
    margin-right: 0;
  }
  .container-body .sidebar ul li {
    margin: 15px 0;
    padding: 5px 0;
  }
  .container-body .sidebar ul li a {
    font-size: 10px;
    margin: 0;
    padding: 0;
  }

  .container-body .sidebar ul .dashboard {
    background: #00004d;
    color: #fff;
    border-radius: 8px;
    margin: 15px 0;
    padding: 5px 0;
  }

  .searchbox input {
    padding: 8px 0 8px 20px;
  }

  .searchbox input:focus {
    padding: 8px 50px 8px 20px;
  }

  .cards::-webkit-scrollbar {
    width: 10px !important;
  }
  .container-body .sidebar ul li:hover {
    background: none;
    color: #000000;
  }
  .container-body .sidebar ul li:hover a {
    color: #000000;
  }

  .container-body .sidebar ul .dashboard {
    background: none;
    border-radius: 8px;
    position: relative;
  }
  .container-body .sidebar ul .dashboard::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #00004d;
  }

  .container-body .sidebar ul .dashboard a {
    color: #000000;
  }
}
/* ------------------------------------- */
.main-body {
  height: 30vh;
}
.cards {
  overflow-y: auto;
  height: 75vh;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px !important;
}
.cards::-webkit-scrollbar {
  width: 20px;
}

.cards::-webkit-scrollbar-thumb {
  background-color: #2980b9;
  border-radius: 10px;
}

.cards::-webkit-scrollbar-track {
  background-color: #ccc5c5f1;
  border-radius: 10px;
}

.container-body .main-body .headtittle {
  padding: 15px;
  margin: 20px;
  margin-bottom: 0;
}

.container-body .main-body .headtittle span {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
}

.container-body .main-body .headtittle h2 {
  font-size: 25px;
  font-weight: normal;
}

.container-body .main-body .cards {
  /* display: grid;
  grid-template-rows: 180px 140px;
  grid-gap: 20px;
  padding: 20px; */
}

.container-body .main-body .cards .row-1 {
}

.container-body .main-body .cards .row-2 {
  width: 100%;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 20px;
}

.container-body .main-body .cards .row .col {
  width: 18rem;
  height: 20rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 200px) and (max-width: 550px) {
  .container-body .main-body .cards .row .col {
    transform: scale(0.7);
    margin-left: -3rem;
  }
  .grid {
    display: none;
  }
}

.container-body .main-body .cards .row .col .cardtittle {
  width: 100%;
  font-weight: 900;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.container-body .main-body .cards .row .col .cardtext {
  width: 100%;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}
.container-body .main-body .cards .row .col .cardtext-italic {
  width: 100%;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
  font-style: italic;
  font-size: 14px;
}

.balance-card {
  position: relative;
}

.balance-card h2 {
  font-size: 28px;
  padding: 10px 20px;
}

.balance-card span {
  font-size: 16px;
  font-weight: bold;
  padding: 20px;
  color: #33cc33;
}

.balance-card img {
  position: absolute;
  top: 5em;
  left: 14em;
}

.debit-card {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 18px;
  background-position: center;
  background-size: cover;
  background: #8aa4ee;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.debit-card .cardnumber {
  font-size: 25px;
  padding: 10px;
  /* padding-right: 0; */
  background: rgba(255, 255, 255, 0.6);
  color: #000;
  font-weight: normal;
  text-shadow: 1px 1px 1px rgba(16, 16, 16, 0.1),
    1px 2px 1px rgba(16, 16, 16, 0.1), 1px 3px 1px rgba(16, 16, 16, 0.1),
    1px 4px 1px rgba(16, 16, 16, 0.1), 1px 5px 1px rgba(16, 16, 16, 0.1);
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.debit-card .cardholder {
  font-size: 18px;
  padding: 20px;
  font-weight: normal;
}

.price {
  width: 100%;
  font-family: digital;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.col .t-i,
.p-i,
.u-i,
.i-s {
  font-size: 25px;
  font-weight: 700;
  /* padding: 20px 5px 20px 20px; */
  display: inline-block;
  text-align: center;
  /* justify-content: center; */
}

.col .status {
  font-size: 18px;
  padding: 6px 20px;
  border-radius: 6px;
  color: #fff;
  position: relative;
  bottom: 3px;
}

.total-invoice {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.total-invoice .status {
  font-weight: 700;
  color: #339933;
  font-size: 16px;
  padding-left: 5px;
}

.paid-invoice .status {
  background: #0066cc;
}

.unpaid-invoice .status {
  background: #ff0066;
}

.invoice-sent .status {
  background: #ffcc00;
}

.button-70 {
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  height: 30px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 0.8em;
  margin: 5px;
  padding: 5px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.ip {
  border: none;
  border-radius: 30px;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: #b2d2ff;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.login-box form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #ffffff;
  text-align: center;
  font-size: 2rem;
}

.login-box .user-box {
  width: 100%;
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.user-box input:focus ~ label,
.user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #ffffff;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  /* display: inline-block; */
  padding: 10px 20px;
  color: #000000;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
  margin-left: auto;
  margin-right: auto;
}

.login-box a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

.chi {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.chi input {
  width: 100px;
  height: 30px;
  border: none;
  border-radius: 20px;
  text-align: center;
}

.chi input:focus {
  outline: none;
}

.grid {
  position: relative;
  /* border: 1px solid #000; */
  padding-top: 37px;
  background: #c9adc0;
  width: 100%;
  border-radius: 5px;
}

.grid-container {
  width: 100%;
  overflow-y: auto;
  height: 200px;
}

table {
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid black; */
}

td + td {
  border-left: 1px solid #000;
  max-width: 100px !important;
  /* border: 1px solid black; */
}

td,
th {
  border-bottom: 1px solid #000;
  background: #fff;
  color: #000;
  padding: 10px 0;
}

th {
  width: 100%;
  height: 0;
  line-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: transparent;
  border: none;
  white-space: nowrap;
}

th div {
  position: absolute;
  text-wrap: wrap;
  background: transparent;
  color: #000000;
  padding: 9px 25px;
  top: 0;
  margin-left: -25px;
  line-height: normal;
}
tr {
  width: 100%;
}

.main-logo {
  position: absolute;
  width: 95%;
  bottom: 70vh;
  display: flex;
  justify-content: center;
}

.main-logo img {
  width: 400px;
  max-width: 80vw;
  height: auto;
}

.nav-logo img {
  margin: 0;
  margin-left: 25%;
  width: 200px;
  text-shadow: 10px 10px 10px black;
}
